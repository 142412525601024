@import "../node_modules/modern-normalize/modern-normalize.css";

$yes: lightgreen;
$no: salmon;

body {
  margin: 1rem;
}

h1 {
  font-size: 1rem;
}

.button {
  $color: #222;
  border-radius: 0.2rem;
  padding: 0.7rem 1.2rem;
  border: 1px solid $color;
  background: transparent;

  &:not(:disabled):hover,
  &:not(:disabled):active {
    cursor: pointer;
    background-color: #ddd;
  }

  &.tak.active {
    background-color: $yes;
  }
  &.nie.active {
    background-color: $no;
  }
}

.buttonSmall {
  padding: 0.1rem 0.5rem;
}

.inlineBlock {
  display: inline-block;
}

.ml1 {
  margin-left: 1rem;
}

.ml05 {
  margin-left: 0.5rem;
}

.mr1 {
  margin-right: 1rem;
}

.mr05 {
  margin-right: 0.5rem;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;

  &.yes {
    background-color: lighten($yes, 20%);
  }
  &.bench {
    background-color: lighten(yellow, 30%);
  }
  &.no {
    background-color: lighten($no, 20%);
  }
}

hr {
  margin: 2rem 0;
}

.headline {
  font-size: 2rem;
  color: #f44;
}

.generalInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * {
    margin-bottom: 1rem;
  }
}

.monthRow {
  display: block;
  padding-bottom: 1rem;
}

.month {
  width: 5rem;
}
